import styles from "./footer.module.scss";
import { Link, useShared } from "@interactivevision/visitor-react";
import ContactForm from "../views/_partials/contact-form";
import ContactWebp from "@public/static/images/contact/contact.webp";
import ContactImg from "@public/static/images/contact/contact.jpg";
import ContactMdWebp from "@public/static/images/contact/contact_md.webp";
import ContactMd from "@public/static/images/contact/contact_md.jpg";
import { SectionWrapper } from "./wrappers";
import { route } from "@interactivevision/routing";
import CompanyLogo from "@assets/images/company-logo.png";
import { ReactComponent as PhoneIcon } from "@assets/icons/phone.svg";
import { ReactComponent as EmailIcon } from "@assets/icons/email.svg";
import { ReactComponent as PinIcon } from "@assets/icons/pin.svg";
import { ReactComponent as Linkedin } from "@assets/icons/linkedin.svg";
import { ReactComponent as Facebook } from "@assets/icons/facebook.svg";
import { ReactComponent as Instagram } from "@assets/icons/instagram.svg";
import { ReactComponent as IvLogo } from "@assets/icons/iv-logo.svg";
import { $t } from "@interactivevision/translations";
import Paragraph from "../typography/paragraph";

export default function Footer() {
  const shared = useShared();
  return (
    <footer className={styles.footer}>
      <section className={styles.upper}>
        <div className={styles.background}>
          <picture>
            <source srcSet={ContactWebp} type="image/webp" media="(min-width: 1026px)" />
            <source srcSet={ContactImg} type="image/jpg" media="(min-width: 1026px)" />
            <source
              srcSet="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdj+P///38ACfsD/QVDRcoAAAAASUVORK5CYII="
              type="image/png"
              media="(max-width: 440px)"
            />
            <img src={ContactImg} alt="Contact" />
          </picture>
        </div>

        <SectionWrapper as="div">
          <ContactForm className={styles.form} />
        </SectionWrapper>
      </section>

      <SectionWrapper className={styles.middle}>
        <Link href={route("homepage_module")} className={styles.logo}>
          <img src={CompanyLogo} alt="Solo" />
        </Link>

        <div className={styles.contact}>
          {__i18n_current !== "en" && (
            <div className={styles.box}>
              <div className={styles.content}>
                <div className={styles.title}>Monika Oleksowicz-Kucharczyk</div>
                <a href="tel:0048 783 787 978">
                  <PhoneIcon />
                  0048 783 787 978
                </a>
                <br />
                <a href="mailto:export@grupasolo.com">
                  <EmailIcon />
                  export@grupasolo.com
                </a>
              </div>
            </div>
          )}

          {__i18n_current === "en" && (
            <div className={styles.box}>
              <div className={styles.content}>
                <div className={styles.title}>Joanna Rebisz</div>
                <a href="tel:001 201 893 0747">
                  <PhoneIcon />
                  001 201 893 0747
                </a>
                <br />
                <a href="mailto:joanna@grupasolo.com">
                  <EmailIcon />
                  joanna@grupasolo.com
                </a>
              </div>
            </div>
          )}

          <div className={styles.box}>
            <PinIcon />
            <div className={styles.content}>
              Brandwica 37-464, Poland <br />
              <a href="https://goo.gl/maps/e2LSMqZwk76RHRM46" rel="noreferrer" target="_blank">
                ul. Orzeszkowej 28
              </a>
            </div>
          </div>
        </div>
      </SectionWrapper>
      <SectionWrapper className={styles.lower}>
        <div className={styles.socials}>
          <a href={shared?.contact.linkedin} title="Linkedin">
            <Linkedin />
          </a>
          <a href={shared?.contact.facebook} title="Facebook">
            <Facebook />
          </a>
          <a href={shared?.contact.instagram} title="Instagram">
            <Instagram />
          </a>
        </div>

        <div className={styles.rights}>
          <Paragraph size={8}>{$t("general.footer-rights")}</Paragraph>
          <a
            href="https://interactivevision.pl/"
            target="_blank"
            rel="noreferrer"
            className={styles.realisation}>
            <span>{$t("general.footer-realisation")}</span>
            <IvLogo />
          </a>
        </div>
      </SectionWrapper>
    </footer>
  );
}
