import styles from './base.module.scss';
import Navbar from '../layout/navbar';
import Footer from './footer';
import { ContactModal } from '../modals/contact-modal';

export const Base = ({ children }) => {
  return (
    <>
      <Navbar/>
      <main className={styles.base}>
        {children}
      </main>
      <Footer/>
      <ContactModal/>
    </>
  );
};
