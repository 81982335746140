import { Modal, ModalContent } from "./modal";
import { ContactHeader } from "../views/_partials/contact-form";
import { useState, useEffect } from "react";
import styles from "./contact-modal.module.scss";
import { ReactComponent as CloseIcon } from "@assets/icons/close.svg";
import { $t } from "@interactivevision/translations";
import { ContactMessageModal } from "./contact-message-modal";
import { Form } from "react-final-form";
import { FieldInput } from "../form/fields/field-input";
import { useSubmitForm } from "../hooks/use-submit-form";
import { GridRow } from "../components/grid";
import { FieldTextarea } from "../form/fields/field-textarea";
import { FieldCheckbox } from "../form/fields/field-checkbox";
import { Button } from "../components/button";
import useCaptchaButton from "../hooks/use-captcha-button";

type ContactModalProps = {};

export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

export function openContactModal(event, category?: string, product?: string) {
    document.dispatchEvent(
        new CustomEvent(OPEN_MODAL, {
            detail: {
                category: category,
                product: product,
            },
        })
    );
}

export function closeContactModal() {
    document.dispatchEvent(new Event(CLOSE_MODAL));
}

export function ContactModal() {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [category, setCategory] = useState<string>("");
    const [product, setProduct] = useState<string>("");
    const { onSubmit, showModal, setShowModal, submitting } = useSubmitForm();
    const { CaptchaButton, setLoadCaptcha } = useCaptchaButton();

    const handleClose = () => setIsOpen(false);

    useEffect(() => {
        function openModal(event) {
            setIsOpen(true);
            setCategory("");
            setProduct("");

            if (event.detail.category) {
                setCategory(event.detail.category);
            }
            if (event.detail.product) {
                setProduct(event.detail.product);
            }
        }

        function closeModal() {
            setIsOpen(false);
        }

        document.addEventListener(OPEN_MODAL, openModal);
        document.addEventListener(CLOSE_MODAL, closeModal);

        return () => document.removeEventListener(CLOSE_MODAL, openModal);
    }, []);

    const makeInitialValues = () => {
        const values = {
            token: "",
            is_agree_terms: false,
            language: __i18n_current,
        };

        if (product) {
            values["product"] = product;
        }

        if (category) {
            values["category"] = category;
        }

        return values;
    };

    return (
        <Modal
            onClose={handleClose}
            isOpen={isOpen}
            className={styles.modal}
            size="md"
        >
            <ModalContent className={styles.content}>
                <>
                    <button
                        onClick={handleClose}
                        className={styles.close}
                        aria-label={$t("general.menu_close")}
                    >
                        <CloseIcon />
                    </button>

                    <ContactMessageModal
                        isOpen={showModal}
                        onClose={() => setShowModal(false)}
                    />
                    <ContactHeader />

                    <Form onSubmit={onSubmit} initialValues={makeInitialValues}>
                        {({ handleSubmit, form }) => (
                            <form
                                onSubmit={handleSubmit}
                                noValidate
                                className={styles.form}
                                onFocus={() => setLoadCaptcha(true)}
                                tabIndex={0}
                            >
                                <FieldInput type="hidden" name="token" />
                                <GridRow>
                                    <FieldInput
                                        label={$t("general.form-name")}
                                        name="name"
                                        required
                                    />
                                </GridRow>
                                <GridRow>
                                    <FieldInput
                                        label={$t("general.form-email")}
                                        name="email"
                                        type="email"
                                        required
                                    />
                                </GridRow>
                                <GridRow>
                                    <FieldInput
                                        label={$t("general.form-phone")}
                                        name="phone"
                                        required
                                    />
                                </GridRow>
                                <GridRow>
                                    <FieldTextarea
                                        name="description"
                                        label={$t("general.form-text")}
                                    />
                                </GridRow>
                                <GridRow gap={4}>
                                    <FieldCheckbox
                                        name="is_agree_terms"
                                        required
                                        id={`check-${(Math.random() + 1)
                                            .toString(36)
                                            .substring(7)}`}
                                    >
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: $t(
                                                    "general.form-terms"
                                                ),
                                            }}
                                        />
                                    </FieldCheckbox>
                                </GridRow>
                                <GridRow className={styles.buttonWrapper}>
                                    {CaptchaButton ? (
                                        <CaptchaButton
                                            form={form}
                                            submitting={submitting}
                                        />
                                    ) : (
                                        <Button type="button" disabled={true}>
                                            {$t("general.form-submit")}
                                        </Button>
                                    )}
                                </GridRow>
                            </form>
                        )}
                    </Form>
                </>
            </ModalContent>
        </Modal>
    );
}
