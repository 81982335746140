import React, { useState, useEffect, FunctionComponent } from 'react';
import { FormApi } from 'final-form';

export type CaptchaButtonProps = {
  form: FormApi;
  submitting: boolean;
}
export default function useCaptchaButton() {
  const [loadCaptcha, setLoadCaptcha] = useState<boolean>(false);

  const [CaptchaButton, setCaptchaButton] = useState<React.LazyExoticComponent<FunctionComponent<CaptchaButtonProps>>>();

  useEffect(() => {
    if (loadCaptcha) {
      const captcha = React.lazy(() => import('../views/_partials/contact-form-submit'));
      setCaptchaButton(captcha);
    }
  }, [loadCaptcha]);

  return {
    CaptchaButton,
    setLoadCaptcha,
  };
}