import React, {FunctionComponent} from 'react';
import {Field}                    from 'react-final-form';
import {FormTextarea}             from "../form-textarea";
import {FormTextareaProps}        from "../form-textarea";


export const FieldTextarea: FunctionComponent<FormTextareaProps> = ({name, ...props}) => {
  return (
    <Field name={name} parse={x => x}>
      {({input, meta}) => (
        <FormTextarea {...props} {...input} error={meta.submitError}/>
      )}
    </Field>
  );
};
