import classNames from 'classnames';
import React, { FunctionComponent, PropsWithChildren, DetailedHTMLProps, ButtonHTMLAttributes, ReactNode, MouseEventHandler } from 'react';
import styles from './button.module.scss';

export type ButtonKind = 'primary' | 'secondary' | 'tertiary';

export type BaseButtonProps = {
  /** Styling of the button */
  kind?: ButtonKind;
  /** Stretches the button to it's maximal width */
  fullWidth?: boolean;
  /** Disables the button */
  disabled?: boolean;
  /** Show a circular progress bar */
  loading?: boolean;
  /** Renders the button highlighted */
  tile?: boolean;
  /** Align the button to a given side */
  /** Component classes */
  className?: string;
  icon?: React.ReactNode;
};


export type ButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & BaseButtonProps & {
  prompt?: ReactNode;
};

export const Button: FunctionComponent<PropsWithChildren<ButtonProps>> = ({
  type = 'button',
  className,
  children,
  disabled = false,
  onClick,
  kind = 'primary',
  loading = false,
  fullWidth = false,
  tile = false,
  icon = null,

  ...props
}) => {
  const handleOnClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (onClick) {
      onClick(e);
    }
  };

  const classes = compileButtonClasses({
    className,
    kind,
    fullWidth,
    icon,
  });

  return (
    <button className={classes} disabled={disabled || loading} type={type} onClick={handleOnClick} {...props}>
      {icon}
      {children && (
        <span className={classNames(styles.content, { [styles.invisible]: loading })}>
          {children}
        </span>
      )}
    </button>
  );
};

type ButtonClassCompilerProps = {
  className?: string;
  kind: ButtonKind;
  fullWidth: boolean;
  icon?: React.ReactNode,
};

export function compileButtonClasses(props: ButtonClassCompilerProps) {

  return classNames(
    props.className,
    styles.button,
    styles[props.kind],
    props.icon ? styles.icon : null,
    {
      [styles.fullWidth]: props.fullWidth,
    },
  );
}
