import { createElement, ComponentType, useEffect } from "react";
import { RouterProps } from "@interactivevision/visitor-react";

type SetupOptions = {
  router: ComponentType<RouterProps>;
  props: RouterProps;
};

import TagManager from "react-gtm-module";
import ChatBot from "./components/chat-box";

const tagManagerArgs = {
  gtmId: "GTM-PHDXGLG",
};

export default function app({ router, props }: SetupOptions) {
  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <>
      {createElement(router, props)}
      <ChatBot />
    </>
  );
}
