import styles from "./contact-form.module.scss";
import React from "react";
import { Form } from "react-final-form";
import { useSubmitForm } from "../../hooks/use-submit-form";
import { ContactMessageModal } from "../../modals/contact-message-modal";
import { FieldTextarea } from "../../form/fields/field-textarea";
import { Button } from "../../components/button";
import { FieldCheckbox } from "../../form/fields/field-checkbox";
import { FieldInput } from "../../form/fields/field-input";
import { GridRow } from "../../components/grid";
import classNames from "classnames";
import SectionTitle from "../../typography/section-title";
import Paragraph from "../../typography/paragraph";
import { $t } from "@interactivevision/translations";
import { useShared } from "@interactivevision/visitor-react";
import useCaptchaButton from "../../hooks/use-captcha-button";
import { FormApi } from "final-form";

type Props = {
  className?: string;
};

export default function ContactForm({ className }: Props) {
  const { onSubmit, showModal, setShowModal, submitting } = useSubmitForm();

  return (
    <>
      <ContactMessageModal isOpen={showModal} onClose={() => setShowModal(false)} />

      <Form
        onSubmit={onSubmit}
        initialValues={{
          token: "",
          category: "",
          is_agree_terms: false,
          language: __i18n_current,
        }}>
        {({ handleSubmit, form }) => (
          <ContactFormContent
            className={className}
            handleSubmit={handleSubmit}
            form={form}
            submitting={submitting}
          />
        )}
      </Form>
    </>
  );
}

type FormContentType = {
  className?: string;
  handleSubmit: any;
  submitting: boolean;
  form: FormApi;
  children?: React.ReactNode;
};

export function ContactFormContent({
  className,
  handleSubmit,
  submitting,
  form,
  children,
}: FormContentType) {
  const { CaptchaButton, setLoadCaptcha } = useCaptchaButton();

  return (
    <div
      className={classNames(styles.formWrapper, className)}
      onFocus={() => setLoadCaptcha(true)}
      tabIndex={0}>
      <ContactHeader />
      <form onSubmit={handleSubmit} noValidate className={styles.form}>
        <FieldInput type="hidden" name="token" />
        <GridRow>
          <FieldInput label={$t("general.form-name")} name="name" required />
        </GridRow>
        <GridRow>
          <FieldInput label={$t("general.form-email")} name="email" type="email" required />
        </GridRow>
        <GridRow>
          <FieldInput label={$t("general.form-phone")} name="phone" required />
        </GridRow>
        <GridRow>
          <FieldTextarea name="description" label={$t("general.form-text")} />
        </GridRow>
        <GridRow gap={4}>
          <FieldCheckbox
            name="is_agree_terms"
            required
            id={`check-${(Math.random() + 1).toString(36).substring(7)}`}>
            <span
              dangerouslySetInnerHTML={{
                __html: $t("general.form-terms"),
              }}
            />
          </FieldCheckbox>
        </GridRow>
        <GridRow className={styles.buttonWrapper}>
          {CaptchaButton ? (
            <CaptchaButton form={form} submitting={submitting} />
          ) : (
            <Button type="button" disabled={true}>
              {$t("general.form-submit")}
            </Button>
          )}
        </GridRow>
        {children}
      </form>
    </div>
  );
}

export function ContactHeader() {
  return (
    <header className={styles.header}>
      <SectionTitle>{$t("general.contact_us")}</SectionTitle>
      <Paragraph size={6}>{$t("general.form-description")}</Paragraph>
    </header>
  );
}
