import styles from './navbar.module.scss';
import { useEffect, useState } from 'react';
import { Link, useLocation, useShared } from '@interactivevision/visitor-react';
import { route } from '@interactivevision/routing';
import CompanyLogo from '@assets/images/company-logo.png';
import { ReactComponent as ArrowRight } from '@assets/icons/arrow-right.svg';
import { ReactComponent as Email } from '@assets/icons/email.svg';
import classNames from 'classnames';
import Burger from './burger';
import { SectionWrapper } from './wrappers';
import { $t } from '@interactivevision/translations';
import { Button } from '../components/button';
import { openContactModal } from '../modals/contact-modal';
import { Select } from '../form/basic/select';

export default function Navbar() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [scrolled, setScrolled] = useState<boolean>(false);
  const location = useLocation();
  const shared = useShared();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsOpen(false);
    document && document.body.classList.remove('scroll-lock');
  }, [location]);

  useEffect(() => {
    if (document) {
      if (isOpen) document.body.classList.add('scroll-lock');
      else document.body.classList.remove('scroll-lock');
    }
  }, [isOpen]);

  const handleActiveClick = (route: string) => {
    if (location.includes(route)) {
      setIsOpen(false);
      document && document.body.classList.remove('scroll-lock');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 20) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    });
  }, []);

  const handleLanguageChange = (event) => {
    const value = event.target.value;
    if (value) {
      window.location.href = route(`${event.target.value}.homepage_module`);
    }
  };

  return (
    <nav className={classNames(styles.navbar, { [styles.scrolled]: scrolled })} id="navbar">
      <SectionWrapper as="div" className={styles.content}>
        <div className={styles.burgerWrapper} onClick={toggleMenu}>
          <Burger toggle={toggleMenu} active={isOpen}/>
          <span className={styles.burgerLabel}>{$t('general.menu')}</span>
        </div>

        <Link href={route('homepage_module')} className={styles.logo} title={import.meta.env.VITE_APP_NAME}>
          <img src={CompanyLogo} alt="Solo"/>
        </Link>

        <div className={styles.actions}>
          <Button className={styles.contact} onClick={openContactModal} aria-label={$t('general.contact_us')}>
            <span className={styles.text}>{$t('general.contact_us')}</span>
            <Email/>
          </Button>
          <Select options={__i18n_available.map(element => ({
            label: element,
            value: element,
          }))} required defaultValue={__i18n_current} onChange={handleLanguageChange}/>
        </div>

        <div className={classNames(styles.menuWrapper, { [styles.open]: isOpen })}>
          <ul className={styles.menu}>
            {shared?.categories && shared?.categories.map((element, index) => (
              <li className={classNames(styles.link,
                { [styles.active]: location.includes(route('product_category', { path: element.path })) })}
                key={index}
                onClick={() => handleActiveClick(route('product_category', { path: element.path }))}
              >
                <Link href={route('product_category', { path: element.path })}>
                  {element.title}
                  <ArrowRight/>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </SectionWrapper>
    </nav>
  );
};
