import { useEffect } from "react";
import { useRef } from "react";

const codes = {
  sk: "107023562393877",
  en: "116371668105637",
};

const ChatBot = () => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      // @ts-ignore
      ref.current.setAttribute("page_id", codes[__i18n_current] || codes.en);
      // @ts-ignore
      ref.current.setAttribute("attribution", "biz_inbox");

      // @ts-ignore
      window.fbAsyncInit = function () {
        // @ts-ignore
        window.FB.init({
          xfbml: true,
          version: "v17.0",
        });
      };
      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/pl_PL/sdk/xfbml.customerchat.js";
        if (fjs.parentNode) fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");
    }
  }, []);

  return (
    <>
      <div id="fb-root"></div>
      <div ref={ref} id="fb-customer-chat" className="fb-customerchat"></div>
    </>
  );
};
export default ChatBot;
