import { useState } from 'react';
import { submitForm, MethodType } from '../helpers/submit-form';

export function useSubmitForm(url?: string, method?: MethodType, event: string = 'CONTACT_FORM') {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const onSubmit = async (values: Object, api: any) => {
    setSubmitting(true);

    try {
      await submitForm(values, url, method).then(() => {
        setShowModal(true);
        setSubmitting(false);
        api.reset();

        if(window && window.dataLayer) {
              window.dataLayer.push({
                event:event,
              })
        }
      });
    } catch (response: any) {
      setSubmitting(false);
      return response.errors;
    }
  };

  return {
    onSubmit,
    showModal,
    setShowModal,
    submitting,
  };
}
