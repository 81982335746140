import { ReactComponent as EnvelopeIcon } from "../assets/icons/envelope.svg";
import styles from "./contact-message-modal.module.scss";
import { FunctionComponent } from "react";
import Paragraph from "../typography/paragraph";
import { Modal, ModalHeader, ModalContent } from "./modal";
import { $t } from "@interactivevision/translations";

export const ContactMessageModal: FunctionComponent<{
    isOpen: boolean;
    onClose: () => void;
}> = ({ isOpen, onClose }) => {
    return (
        <Modal
            onClose={onClose}
            isOpen={isOpen}
            className={styles.modal}
            size="md"
        >
            <ModalHeader onClose={onClose} className={styles.header}>
                {$t("general.contact_modal_title")}
            </ModalHeader>
            <ModalContent className={styles.content}>
                <EnvelopeIcon className={styles.icon} />
                <Paragraph size={9}>
                    {$t("general.contact_modal_message")}
                </Paragraph>
            </ModalContent>
        </Modal>
    );
};
