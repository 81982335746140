import classNames from 'classnames';
import React, { TextareaHTMLAttributes, FunctionComponent, useState } from 'react';
import styles from './textarea.module.scss';


export type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  max?: number;
}

export const Textarea: FunctionComponent<TextareaProps> = ({ rows = 4, max, ...props }) => {
  const [chars, setChars] = useState<number>(0);

  const handleChange = (event: any) => {
    setChars(event.target.textLength);

    if (typeof props.onChange === 'function') {
      props.onChange(event);
    }
  };

  return (
    <div className={styles.container}>
      <textarea rows={rows} {...props} id={props.id || props.name} maxLength={max} className={classNames(styles.textarea, props.className)} onChange={handleChange}/>
      {max && <span className={styles.count}>{chars}/{max}</span>}
    </div>
  );
};
