import React, { FunctionComponent, PropsWithChildren, useEffect, useState, HTMLAttributes } from "react";
import styles from "./modal.module.scss";
import { ReactComponent as CloseIcon } from "../assets/icons/close.svg";
import classNames from "classnames";
import { useLocation } from "@interactivevision/visitor-react";
import { createPortal } from "react-dom";

export type ModalSize = "sm" | "md" | "lg" | "xl";

type ModalProps = {
  onClose: () => void;
  isOpen: boolean;
  className?: string;
  size: ModalSize;
};
export const Modal: FunctionComponent<PropsWithChildren<ModalProps>> = ({ onClose, isOpen, children, className, size }) => {
  const location = useLocation();
  const [openedLocation, setOpenedLocation] = useState<string>(location);

  useEffect(() => {
    if (isOpen) {
      setOpenedLocation(location);
      document && document.body.classList.add("scroll-lock");
    } else {
      document && document.body.classList.remove("scroll-lock");
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      if (openedLocation !== location) {
        onClose();
      }
    }
  }, [location]);

  if (!isOpen) {
    return null;
  }

  return (
    <ReactPortal>
      <div className={classNames(styles.modal, className, styles[size])}>
        <div className={styles.container}>{children}</div>
      </div>
    </ReactPortal>
  );
};

type ModalElementsBaseProps = HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
};

export function ModalContent({ children, className, ...props }: ModalElementsBaseProps) {
  return (
    <div className={classNames(styles.content, className)} {...props}>
      {children}
    </div>
  );
}

type ModalHeaderProps = ModalElementsBaseProps & {
  onClose?: () => void;
};

export function ModalHeader({ children, onClose, className, ...props }: ModalHeaderProps) {
  return (
    <div className={classNames(styles.header, className)} {...props}>
      {children && <div className={styles.title}>{children}</div>}
      {onClose && (
        <button className={styles.close} onClick={onClose}>
          <CloseIcon />
        </button>
      )}
    </div>
  );
}

export type ReactPortalProps = {
  children?: React.ReactNode;
  target?: string;
};

export const ReactPortal: FunctionComponent<ReactPortalProps> = ({ children, target }) => {
  return createPortal(children, document.querySelector(target ?? "body") as Element);
};
