import React, { FunctionComponent, DetailedHTMLProps, SelectHTMLAttributes } from 'react';
import styles from './select.module.scss';
import { $t } from '@interactivevision/translations';

export type SelectProps = DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> & {
  options: Option[];
  required?: boolean;
};

export type Option = {
  label: string;
  value: number | string;
}

export const Select: FunctionComponent<SelectProps> = ({ id, name, options, required = false, ...props }) => {
  return (
    <select className={styles.select} id={id} name={name} {...props}>
      {(!required) ? <option>{$t('general.select')}</option> : <option disabled>{$t('general.select')}</option>}
      {options.map((element, key) => (
        <option key={key} value={element.value}>{element.label}</option>
      ))}
    </select>
  );
};
