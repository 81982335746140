import React, { FunctionComponent, PropsWithChildren } from 'react';
import styles from './grid.module.scss';
import classNames from 'classnames';

export const Grid: FunctionComponent<PropsWithChildren<{ className?: string, columns?: number }>> = ({ children, className }) => {
  return <div className={classNames(className, styles.grid)}>{children}</div>;
};

export const GridRow: FunctionComponent<PropsWithChildren<{ className?: string; gap?: number }>> = ({ children, className, gap = 2 }) => {
  return <div className={classNames(className, styles.row)} style={{ '--row-gap': gap } as React.CSSProperties}>{children}</div>;
};
